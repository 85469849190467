/* eslint-disable @typescript-eslint/no-empty-function */
import { type UploadJobData } from "@/shared/types"
import { type StateCreator } from "zustand"

import { isFileValid } from "@/lib/files"

export interface UploadJobsState {
  allUploadJobs: UploadJobData[]
  uploadJobs: UploadJobData[]
  prevUploadLength: number
}

export interface UploadJobsAction {
  updateAllUploadJobs: (jobs: UploadJobData[]) => void
  removeUploadJob: (uploadJobId: string) => void
  resetUploadJobs: () => void
  updateUploadJob: (id: string | number, job: UploadJobData) => void
  addUploadJobs: (uploadJobs: UploadJobData[]) => void
  setPrevUploadLength: (length: number) => void
}

export interface UploadModalState {
  showUploadModal: boolean
  showUploadBox: boolean
  showCancelUploadModal: boolean
  uploadBoxCollapsed: string
  currentUploadJobId: string | null
  isScrollDownButtonClicked: boolean
  prevUploadId: string[]
}

export interface UploadModalAction {
  setShowUploadModal: (show: boolean) => void
  setShowUploadBox: (show: boolean) => void
  setShowCancelUploadModal: (show: boolean) => void
  setUploadBoxCollapsed: (collapsed: string) => void
  setCurrentUploadJobId: (jobId: string) => void
  setIsScrollDownButtonClicked: (isClicked: boolean) => void
  setPrevUploadId: (id: string) => void
}

export interface UploadCallbacksState {
  onClickPause: (uploadId: string) => Promise<void>
  onClickResume: (uploadId: string) => Promise<void>
  onClickCancelById: (uploadId: string) => Promise<void>
}

export interface UploadCallbacksAction {
  setOnClickPause: (onClickPause: (uploadId: string) => Promise<void>) => Promise<void>
  setOnClickResume: (onClickResume: (uploadId: string) => Promise<void>) => Promise<void>
  setOnClickCancelById: (onClickCancelById: (uploadId: string) => Promise<void>) => Promise<void>
}

const initialUploadJobsState: UploadJobsState = {
  allUploadJobs: [],
  uploadJobs: [],
  prevUploadLength: 0,
}

const initialUploadModalState: UploadModalState = {
  showUploadModal: false,
  showUploadBox: false,
  showCancelUploadModal: false,
  uploadBoxCollapsed: "close",
  currentUploadJobId: null,
  isScrollDownButtonClicked: false,
  prevUploadId: [],
}

const initialUploadCallbacksState: UploadCallbacksState = {
  onClickPause: async () => {},
  onClickResume: async () => {},
  onClickCancelById: async () => {},
}

export type UploadSlice = UploadJobsState &
  UploadJobsAction &
  UploadModalState &
  UploadModalAction &
  UploadCallbacksState &
  UploadCallbacksAction

export const createUploadSlice: StateCreator<UploadSlice> = (set) => ({
  ...initialUploadJobsState,
  ...initialUploadModalState,
  ...initialUploadCallbacksState,
  updateAllUploadJobs: (jobs: UploadJobData[]) => {
    set({ allUploadJobs: jobs })
  },
  removeUploadJob: (uploadJobId: string) => {
    set((state) => ({
      uploadJobs: state.uploadJobs.filter((job) => job.uploadId !== uploadJobId),
    }))
  },
  resetUploadJobs: () => {
    set({ uploadJobs: [] })
    set({ prevUploadLength: 0 })
  },
  updateUploadJob: (id: string | number, job: UploadJobData) => {
    set((state) => {
      const uploadJobs = state.uploadJobs.map((j) => {
        if (j.uploadId === id) {
          return job
        }
        return j
      })
      uploadJobs.sort((a, b) => (a.uploadId > b.uploadId ? 1 : -1))
      return { uploadJobs }
    })
  },
  addUploadJobs: (uploadJobs: UploadJobData[]) => {
    set((state) => {
      const validJobs = uploadJobs.filter((job) => isFileValid(job.file))
      state.allUploadJobs.push(...validJobs)
      return { uploadJobs: [...state.uploadJobs, ...validJobs] }
    })
  },
  setShowUploadModal: (show: boolean) => {
    set({ showUploadModal: show })
  },
  setShowUploadBox: (show: boolean) => {
    set({ showUploadBox: show })
  },
  setShowCancelUploadModal: (show: boolean) => {
    set({ showCancelUploadModal: show })
  },
  setUploadBoxCollapsed: (collapsed: string) => {
    set({ uploadBoxCollapsed: collapsed })
  },
  setCurrentUploadJobId: (jobId: string) => {
    set({ currentUploadJobId: jobId })
  },
  setPrevUploadLength: (length: number) => {
    set({ prevUploadLength: length })
  },
  setOnClickPause: async (onClickPause: (uploadId: string) => Promise<void>) => {
    set({ onClickPause })
  },
  setOnClickResume: async (onClickResume: (uploadId: string) => Promise<void>) => {
    set({ onClickResume })
  },
  setOnClickCancelById: async (onClickCancelById: (uploadId: string) => Promise<void>) => {
    set({ onClickCancelById })
  },
  setIsScrollDownButtonClicked: (isClicked: boolean) => {
    set({ isScrollDownButtonClicked: isClicked })
  },
  setPrevUploadId: (id: string) => {
    set((state) => {
      if (state.prevUploadId.includes(id)) {
        return { prevUploadId: state.prevUploadId }
      } else {
        return { prevUploadId: [...state.prevUploadId, id] }
      }
    })
  },
})
