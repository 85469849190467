import imageCompression from "browser-image-compression"

import { BLACKLIST_FILE_TYPE, MAX_FILE_SIZE } from "@/lib/constants"

export function validateLogoFileType(logo: File): boolean {
  if (!logo) return true
  const allowedTypes = ["image/png", "image/jpeg", "image/jpg"]

  if (!allowedTypes.includes(logo.type)) {
    return false
  }

  return true
}

export function validateLogoFileSize(logo: File): boolean {
  if (!logo) return true
  if (logo.size > 500000) {
    return false
  }

  return true
}

export function validateFileType(file: File, allowedTypes: string[]): boolean {
  if (!file) return true
  if (!allowedTypes.includes(file.type)) {
    return false
  }

  return true
}

export function validateFileSize(file: File, maxSize: number): boolean {
  if (!file) return true
  if (file.size > maxSize) {
    return false
  }

  return true
}

export function isFileValid(file: File) {
  const fileType = file.type
  const fileSize = file.size

  if (BLACKLIST_FILE_TYPE.some((img) => fileType.includes(img))) {
    return false
  }

  if (MAX_FILE_SIZE <= fileSize) {
    return false
  }

  return true
}

export function getFileExtension(file: File) {
  const splitName = file.type.split("/")
  const fileExtension = splitName[splitName.length - 1]
  return fileExtension
}

export function removeFileExtension(filename: string): string {
  return filename.replace(/\.[^/.]+$/, "")
}

export function formatFileSize(size?: number) {
  if (!size) return "0 B"
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
  return Number((size / Math.pow(1024, i)).toFixed(2)) + " " + ["B", "kB", "MB", "GB", "TB"][i]
}

export async function compressionFile(file: File, maxSizeMB = 0.45, maxWidthOrHeight = 700): Promise<File | undefined> {
  try {
    const options = {
      maxSizeMB,
      maxWidthOrHeight,
      useWebWorker: true,
    }
    const compressedFile = new File([await imageCompression(file, options)], file.name, { type: file.type })
    return compressedFile
  } catch (err) {
    console.log(err)
    return
  }
}
