import { env } from "@/env.mjs"

export const Cookies = {
  Email: "email",
  PassCode: "passCode",
  Cooldown: "cooldown",
  State: "state",
  Slug: "slug",
  CourseKey: "courseKey",
  UserSessionKey: env.NEXT_PUBLIC_USER_SESSION_KEY,
  MemberSessionKey: env.NEXT_PUBLIC_MEMBER_SESSION_KEY,
  SupabaseAccessToken: "supabase_accessToken",
  Provider: "provider",
}

export const Provider = {
  Google: "google",
  Line: "line-login",
}

export const LOGIN = "login"
export const REGISTER = "register"
export const ACTIVATE = "activate"

export const unknownError = "An unknown error occurred. Please try again later."

export const PASSWORD_LOWER_THAN_8 = "ความยาวอย่างน้อย 8 ตัวอักษร"
export const PASSWORD_UPPERCASE = "มีตัวพิมพ์ใหญ่"
export const PASSWORD_LOWERCASE = "มีตัวพิมพ์เล็ก"
export const PASSWORD_NUMBER = "มีตัวเลข"
export const PASSWORD_SPECIAL_CHAR = "มีอักขระพิเศษ เช่น !, @, #, ?"

export const OTHER_OPTION = "อื่น ๆ (โปรดระบุ)"

export const MAX_FILE_SIZE = 30 * 1024 * 1024 * 1024
export const BLACKLIST_FILE_TYPE = [
  ".bmp",
  ".gif",
  ".jpeg",
  ".jpg",
  ".png",
  ".tif",
  ".tiff",
  ".svg",
  ".avif",
  ".webp",
  "image",
]

export const WEBSITE_COLORS = ["grey", "violet", "blue", "green", "red"] as const

export const OPEN = "open" as string
export const CLOSE = "close" as string

export const ONBOARDING_STEPS = [{ label: "แบรนด์" }, { label: "ประเภทคอนเทนต์" }, { label: "ชื่อคลาส" }]

export const BACKGROUND_COLORS = [
  "77dd77",
  "836953",
  "89cff0",
  "99c5c4",
  "9adedb",
  "aa9499",
  "aaf0d1",
  "b2fba5",
  "b39eb5",
  "bdb0d0",
  "bee7a5",
  "befd73",
  "c1c6fc",
  "c6a4a4",
  "cb99c9",
  "ff6961",
  "ff694f",
  "ff9899",
  "ffb7ce",
  "ca9bf7",
]

export const BRANDPAGE = ["contact", "create-brand", "menu"]
